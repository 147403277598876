export const apiConfig = {
    apiUrl: `https://test-api.app.evorra.com`,
    apiUrlCI: `https://test-api.app.evorra.com`,
    apiUrlTest: `https://test-api.app.evorra.com`,
}

export const frontEndV2Url = {
    ciUrl: `https://test-v2.app.evorra.com`,
    prodUrl: `https://test-v2.app.evorra.com`,
    testUrl: `https://test-v2.app.evorra.com`,
    localUrl: 'http://localhost:3001'
}


const local = false

const apiUrl = local ? apiConfig.apiUrlCI : process.env?.REACT_APP_DEV_ENV ? apiConfig.apiUrl : apiConfig.apiUrlCI
const segmentsUrls = local ? frontEndV2Url.localUrl : process.env?.REACT_APP_DEV_ENV ? frontEndV2Url.prodUrl : frontEndV2Url.ciUrl
const externalLoginUrl = local ? frontEndV2Url.localUrl : process.env?.REACT_APP_DEV_ENV ? frontEndV2Url.prodUrl : frontEndV2Url.ciUrl

export const devApi = apiUrl

export const segmentApiUrl = segmentsUrls

export const frontEndV2ExternalLoginUrl = externalLoginUrl + '/api/auth/external-login'